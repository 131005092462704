import React from 'react'
import styled from 'styled-components'

import ImgFooterBg from '../images/img-footer-bg.jpg'
import IconOfficialQRCode from '../images/icon-official-qrcode.png'
import IconBilibiliQRCode from '../images/icon-bilibili-qrcode.png'
import IconChannelQRCode from '../images/icon-channel-qrcode.png'
import IconHelperQRCode from '../images/icon-helper-qrcode.jpg'

const FooterContainer = styled.div`
  width: 100%;
  height: 44rem;
  position: relative;
  display: -webkit-flex; /* Safari */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  @media only screen and (max-width: 1279px) {
    height: auto;
  }
`

const FooterBlurBG = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #727071;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(${ImgFooterBg});
  filter: blur(12px);
  z-index: 1;
`

const FooterInner = styled.div`
  flex: 1 1 auto;
  max-width: 1440px;
  height: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(${ImgFooterBg});
  display: -webkit-flex; /* Safari */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;

  @media only screen and (max-width: 1440px) {
    max-width: 1280px;
  }

  @media only screen and (max-width: 1279px) {
    padding-bottom: 4rem;
    flex-direction: column;
  }

  @media only screen and (max-width: 768px) {
    max-width: 100%;
  }
`

const Title = styled.div`
  color: #FFF;
  margin-bottom: 1.2rem;
  font-size: 2.4rem;

  @media only screen and (max-width: 1279px) {
    text-align: center;
  }
`

const FooterQrcode = styled.div`
  margin: 0 4rem;
  color: #FFF;
  font-size: 1.4rem;
  line-height: 3.4rem;
  flex: 0 1 auto;

  @media only screen and (max-width: 1279px) {
    margin-top: 4rem;
  }
`

const FooterQrcodeImg = styled.img`
  width: auto;
  height: 220px;
  margin: 0;
`

const Footer = () => (
  <FooterContainer className="section footer">
    <FooterBlurBG />

    <FooterInner>
      <FooterQrcode>
        <Title>
          官方直播
        </Title>

        <FooterQrcodeImg
          src={IconOfficialQRCode}
          alt="官方直播"
          title="官方直播"
        />
      </FooterQrcode>

      <FooterQrcode>
        <Title>
          Bilibili 直播
        </Title>

        <FooterQrcodeImg
          src={IconBilibiliQRCode}
          alt="Bilibili 直播"
          title="Bilibili 直播"
        />
      </FooterQrcode>

      <FooterQrcode>
        <Title>
          视频号直播
        </Title>

        <FooterQrcodeImg
          src={IconChannelQRCode}
          alt="视频号直播"
          title="视频号直播"
        />
      </FooterQrcode>

      <FooterQrcode>
        <Title>
          会务助手
        </Title>

        <FooterQrcodeImg
          src={IconHelperQRCode}
          alt="会务助手"
          title="会务助手"
        />
      </FooterQrcode>
    </FooterInner>
  </FooterContainer>
)

export default Footer
