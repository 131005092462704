import React from 'react'
import styled from 'styled-components'

import ImgPrize from '../images/img-prize.png'

import TitleDivider from './titleDivider'

const PrizeContainer = styled.div`
  padding: 4rem 0;
  text-align: center;

  @media only screen and (max-width: 767px) {
    padding-bottom: 0;
  }
`

const PrizeTitle = styled.h2`
  margin-bottom: 0;
  font-size: 4rem;
  color: #FFF;
  text-align: center;
  line-height: 6rem;
`

const PrizeInner = styled.div`
  width: 80%;
  margin: 0 auto;
  margin-top: 6rem;
  margin-bottom: 4rem;
  max-width: 1080px;
  background-color: #717172;

  @media only screen and (max-width: 767px) {
    width: 100%;
    margin-bottom: 0;
  }
`

const PrizeImg = styled.img`
  max-width: 100%;
  width: 800px;
  height: auto;
`

const Prize = () => (
  <PrizeContainer className="section prize">
    <PrizeTitle>
      直播福利
      <TitleDivider />
    </PrizeTitle>

    <PrizeInner>
      <PrizeImg
        src={ImgPrize}
        alt="直播福利 - 中国技术力量 | ECUG Con 2022 全球技术大会"
        title="直播福利 - 中国技术力量 | ECUG Con 2022 全球技术大会"
      />
    </PrizeInner>
  </PrizeContainer>
)

export default Prize
