import React from 'react'
import styled from 'styled-components'

const BuyTicketsContainer = styled.div`
  padding: 6rem 0;
  text-align: center;
`

const BuyTicketsButton = styled.a`
  padding: 1rem 3rem;
  color: #FFF;
  border-radius: .2rem;
  background-color: #F39801;
  font-size: 2.4rem;
  text-decoration: none;
  box-shadow: .6rem .6rem .2rem .1rem rgba(243, 152, 1, .4);
  transition: all .3s;
  outline: none;

  &:active,
  &:focus {
    color: #FFF;
    background-color: #F39801;
  }

  &:hover {
    color: #F39801;
    background-color: #FFF;
  }
`

const BuyTickets = () => (
  <BuyTicketsContainer className="section buy-ticket">
    <BuyTicketsButton
      href="http://live-market.qiniu.com/watch/10786937"
      target="_blank"
      rel="noopener nofollow"
      title="预约直播 - 中国技术力量 | ECUG Con 2022 全球技术大会"
    >
      预约直播
    </BuyTicketsButton>
  </BuyTicketsContainer>
)

export default BuyTickets
